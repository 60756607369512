@import '@styles/sass/mixins/rem';

.accordionItem {
  &__title h1,
  &__title h2,
  &__title h3,
  &__title h4,
  &__title h5,
  &__title p {
    font-size: rem(16);
    font-weight: bold;
  }

  &__panel div {
    z-index: 0;
  }

  &__panel div:first-child {
    overflow: initial !important;
  }

  &__panel h1,
  &__panel h2,
  &__panel h3,
  &__panel h4,
  &__panel h5,
  &__panel h6,
  &__panel p,
  &__panel ul,
  &__panel ol,
  &__panel li {
    font-size: rem(16);
    line-height: rem(24);
  }

  a:link,
  a:visited {
    text-decoration: underline;
    color: #005098;
  }

  a:hover,
  a:active {
    color: #003d74;
  }
}

@media only screen and (max-width: rem(767)) {
  .accordionItem {
    &__title h1,
    &__title h2,
    &__title h3,
    &__title h4,
    &__title h5,
    &__title p,
    &__title span {
      font-size: rem(14);
      font-weight: bold;
      line-height: rem(20);
    }

    &__panel p,
    &__panel ul,
    &__panel ol,
    &__panel li {
      font-size: rem(14);
      line-height: rem(20);
    }
  }
}

.customEditor {
  color: #4e4b64;
  li {
    margin-left: rem(20);
  }
  a {
    text-decoration-line: underline;
    color: var(--buttonPrimaryBg);
    &:hover {
      color: var(--buttonPrimaryBgHover);
    }
  }
  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }
}

@media only screen and (max-width: 767px) {
  .customEditor {
    table {
      max-width: 85vw;
      width: 85vw;
      font-size: 2vw;
    }
  }
}
