.content {
  align-items: center;
  display: grid;
  grid-auto-flow: column dense;
  flex-wrap: nowrap;
  list-style: none;
  overflow-x: scroll;
  grid-column-gap: 0.25rem;
  scroll-padding-left: 1rem;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.content::-webkit-scrollbar {
  display: none;
}
.content li {
  scroll-snap-align: start end;
  scroll-snap-stop: always;
}
